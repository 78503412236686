@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: white;
}

.ant-btn-primary {
  background-color: #1677ff;
}

.border-light {
  border-color: rgba(5, 5, 5, 0.06);
}

.bg-light {
  background-color: rgba(235, 238, 242, 0.7);
}

.w-50 {
  width: 200px;
}

.chatInput {
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: white;
  border-top: 1px solid rgba(5, 5, 5, 0.06);
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
}

.chatInput input[type="text"] {
  outline: none;
}

.chatInput__sendIcon {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #1677ff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.chatInput__sendIcon:hover {
  background-color: #1677ff;
  color: white;
}

.chatInput__micIcon {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: grey;
  cursor: pointer;
  transition: all 1s ease-in;
}

#chatInput__textarea::-webkit-scrollbar {
  display: none;
}

.listening {
  color: #1677ff;
  animation: pluse 1s infinite;
}

@keyframes pluse {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}

.text-3x {
  font-size: 27px;
  line-height: 2.125rem;
}

/* width */
::-webkit-scrollbar {
  width: 7px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0f85e0 !important;
  border-radius: 5px;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.plugin:hover > div {
  visibility: visible;
}

.data-segment::-webkit-scrollbar {
  display: none;
}

.data-segment {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
